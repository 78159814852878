<template>
    <div :id="blok._uid">
        <div class="relative inset-x-1/2 mx-[-50vw] w-screen">
            <nuxt-picture v-if="blok.image?.filename"
                :src="blok.image?.filename"
                :alt="blok.title"
                provider="storyblok"
                loading="lazy"
                sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw 2xl:100vw 3xl:100vw"
                :img-attrs="{
                    class: 'w-screen aspect-[2.25/1] object-cover h-80 lg:h-[30rem]',
                }" />

            <div class="absolute inset-x-1 top-0 z-50 mx-auto flex h-3/4 flex-col justify-center">
                <p class="z-50 mx-auto w-full px-md text-center font-prenton-cond text-6xl
                    font-bold text-white md:text-8xl lg:text-10xl">
                    {{ blok.title }}
                </p>

                <v-button v-if="blok.button_text !== '' && blok.button_link !== undefined"
                    :to="blok.button_link.url ? blok.button_link.url : blok.button_link.cached_url"
                    size="wide"
                    theme="gray"
                    class="mx-auto mt-xl w-1/3 text-center">
                    {{ blok.button_text }}
                </v-button>
            </div>
        </div>

        <div v-if="!inWideSlider"
            class="relative rounded-xs bg-white lg:mt-[-112px]">
            <v-h5 v-if="blok.content_title !== ''"
                class="pt-xl text-center uppercase">
                {{ blok.content_title }}
            </v-h5>

            <div class="grid grid-cols-2 rounded-xs md:grid-cols-3
                lg:auto-cols-auto lg:grid-flow-col lg:grid-cols-none">
                <template v-for="content in blok.content"
                    :key="content._uid">
                    <v-storyblok-component :blok="content" />
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { BlokComponent } from '~~/types';

defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-info' | 'sb-info-item' }>
}>();

const inWideSlider = inject('sb.inWideSlider', false);
</script>

<style scoped>

</style>
